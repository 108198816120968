@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  background-color: black;
  color: white;
  line-height: 1.5;
  letter-spacing: 0.11rem;
  font-size: 1.2rem;
}
