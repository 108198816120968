.description {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: space-evenly;
  margin-left: 10%;
  max-width: 50%;
}

.description h1 {
  font-weight: 900;
  font-style: normal;
  letter-spacing: -0.06rem;
  font-size: 4rem;
}

.description_subtitle {
  width: 100;
  letter-spacing: 0.2rem;
}

.description h3 {
  color: #bbbbbb;
  font-size: 2rem;
}

.description span {
  font-size: 3rem;
}

/* arrow  position absolute*/
.arrow {
  position: absolute;
  bottom: 0;
  left: 50%;
  line-height: 2.5;
}
