footer {
  width: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 10%;
  color: #bbbbbb;
  font-weight: 900;
}

footer h2 {
  font-size: 4.5rem;
  display: inline;
}

.footer__contact {
  max-width: 65%;
}

.footer__contact h4,
footer a {
  color: white;
}

footer a {
  text-decoration: none;
  text-transform: uppercase;
  padding: 5%;
  font-weight: bold;
}
