header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.5rem 2rem;
  align-items: center;
  color: black;
  height: 10vh;
}

header div h1 {
  color: white;
  letter-spacing: -1.5rem;
  font-size: 4rem;
}

header nav a {
  margin: 5rem;
  text-decoration: none;
}

header nav a span {
  letter-spacing: 0.11rem;
  font-size: 1rem;
  color: #bbbbbb;
  font-weight: 900;
}

header nav a span:hover {
  color: white;
}
